import React from 'react';
import { Provider } from 'react-redux';
import store from './src/state/store';
// import 'bootstrap/dist/css/bootstrap.min.css'

import 'assets/styles/bootstrap.min.css';
import 'simplebar/dist/simplebar.min.css';
import 'assets/styles/global.css';

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};
